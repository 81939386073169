import 'reflect-metadata';

import { unitOfTime } from 'moment';

export enum TCurrency {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BSD = 'BSD',
  BWP = 'BWP',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHF = 'CHF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  IQD = 'IQD',
  INR = 'INR',
  ISK = 'ISK',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KRW = 'KRW',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRO = 'MRO',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  STD = 'STD',
  SVC = 'SVC',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  UYU = 'UYU',
  UZS = 'UZS',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XCD = 'XCD',
  XOF = 'XOF',
  XPF = 'XPF',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMW = 'ZMW',
}

export const currencyToCountryCodeMap = new Map<TCurrency, string>([
  [TCurrency.AED, 'AE'], // United Arab Emirates
  [TCurrency.AFN, 'AF'], // Afghanistan
  [TCurrency.ALL, 'AL'], // Albania
  [TCurrency.AMD, 'AM'], // Armenia
  [TCurrency.ANG, 'AN'], // Netherlands Antilles
  [TCurrency.AOA, 'AO'], // Angola
  [TCurrency.ARS, 'AR'], // Argentina
  [TCurrency.AUD, 'AU'], // Australia
  [TCurrency.AWG, 'AW'], // Aruba
  [TCurrency.AZN, 'AZ'], // Azerbaijan
  [TCurrency.BAM, 'BA'], // Bosnia and Herzegovina
  [TCurrency.BBD, 'BB'], // Barbados
  [TCurrency.BDT, 'BD'], // Bangladesh
  [TCurrency.BGN, 'BG'], // Bulgaria
  [TCurrency.BIF, 'BI'], // Burundi
  [TCurrency.BMD, 'BM'], // Bermuda
  [TCurrency.BND, 'BN'], // Brunei Darussalam
  [TCurrency.BOB, 'BO'], // Bolivia
  [TCurrency.BRL, 'BR'], // Brazil
  [TCurrency.BSD, 'BS'], // Bahamas
  [TCurrency.BWP, 'BW'], // Botswana
  [TCurrency.BZD, 'BZ'], // Belize
  [TCurrency.CAD, 'CA'], // Canada
  [TCurrency.CDF, 'CD'], // Congo, The Democratic Republic of the
  [TCurrency.CHF, 'CH'], // Switzerland
  [TCurrency.CLP, 'CL'], // Chile
  [TCurrency.CNY, 'CN'], // China
  [TCurrency.COP, 'CO'], // Colombia
  [TCurrency.CRC, 'CR'], // Costa Rica
  [TCurrency.CVE, 'CV'], // Cape Verde
  [TCurrency.CZK, 'CZ'], // Czech Republic
  [TCurrency.DJF, 'DJ'], // Djibouti
  [TCurrency.DKK, 'DK'], // Denmark
  [TCurrency.DOP, 'DO'], // Dominican Republic
  [TCurrency.DZD, 'DZ'], // Algeria
  [TCurrency.EGP, 'EG'], // Egypt
  [TCurrency.ETB, 'ET'], // Ethiopia
  [TCurrency.EUR, 'EU'], // European Union
  [TCurrency.FJD, 'FJ'], // Fiji
  [TCurrency.FKP, 'FK'], // Falkland Islands (Malvinas)
  [TCurrency.GBP, 'GB'], // United Kingdom
  [TCurrency.GEL, 'GE'], // Georgia
  [TCurrency.GHS, 'GH'], // Ghana
  [TCurrency.GIP, 'GI'], // Gibraltar
  [TCurrency.GMD, 'GM'], // Gambia
  [TCurrency.GNF, 'GN'], // Guinea
  [TCurrency.GTQ, 'GT'], // Guatemala
  [TCurrency.GYD, 'GY'], // Guyana
  [TCurrency.HKD, 'HK'], // Hong Kong
  [TCurrency.HNL, 'HN'], // Honduras
  [TCurrency.HRK, 'HR'], // Croatia
  [TCurrency.HTG, 'HT'], // Haiti
  [TCurrency.HUF, 'HU'], // Hungary
  [TCurrency.IDR, 'ID'], // Indonesia
  [TCurrency.ILS, 'IL'], // Israel
  [TCurrency.IQD, 'IQ'], // Iraq
  [TCurrency.INR, 'IN'], // India
  [TCurrency.ISK, 'IS'], // Iceland
  [TCurrency.JMD, 'JM'], // Jamaica
  [TCurrency.JOD, 'JO'], // Jordan
  [TCurrency.JPY, 'JP'], // Japan
  [TCurrency.KES, 'KE'], // Kenya
  [TCurrency.KGS, 'KG'], // Kyrgyzstan
  [TCurrency.KHR, 'KH'], // Cambodia
  [TCurrency.KMF, 'KM'], // Comoros
  [TCurrency.KRW, 'KR'], // Korea, Republic of
  [TCurrency.KYD, 'KY'], // Cayman Islands
  [TCurrency.KZT, 'KZ'], // Kazakhstan
  [TCurrency.LAK, 'LA'], // Lao People's Democratic Republic
  [TCurrency.LBP, 'LB'], // Lebanon
  [TCurrency.LKR, 'LK'], // Sri Lanka
  [TCurrency.LRD, 'LR'], // Liberia
  [TCurrency.LSL, 'LS'], // Lesotho
  [TCurrency.MAD, 'MA'], // Morocco
  [TCurrency.MDL, 'MD'], // Moldova, Republic of
  [TCurrency.MGA, 'MG'], // Madagascar
  [TCurrency.MKD, 'MK'], // Macedonia, the former Yugoslav Republic of
  [TCurrency.MMK, 'MM'], // Myanmar
  [TCurrency.MNT, 'MN'], // Mongolia
  [TCurrency.MOP, 'MO'], // Macao
  [TCurrency.MRO, 'MR'], // Mauritania
  [TCurrency.MUR, 'MU'], // Mauritius
  [TCurrency.MVR, 'MV'], // Maldives
  [TCurrency.MWK, 'MW'], // Malawi
  [TCurrency.MXN, 'MX'], // Mexico
  [TCurrency.MYR, 'MY'], // Malaysia
  [TCurrency.MZN, 'MZ'], // Mozambique
  [TCurrency.NAD, 'NA'], // Namibia
  [TCurrency.NGN, 'NG'], // Nigeria
  [TCurrency.NIO, 'NI'], // Nicaragua
  [TCurrency.NOK, 'NO'], // Norway
  [TCurrency.NPR, 'NP'], // Nepal
  [TCurrency.NZD, 'NZ'], // New Zealand
  [TCurrency.PAB, 'PA'], // Panama
  [TCurrency.PEN, 'PE'], // Peru
  [TCurrency.PGK, 'PG'], // Papua New Guinea
  [TCurrency.PHP, 'PH'], // Philippines
  [TCurrency.PKR, 'PK'], // Pakistan
  [TCurrency.PLN, 'PL'], // Poland
  [TCurrency.PYG, 'PY'], // Paraguay
  [TCurrency.QAR, 'QA'], // Qatar
  [TCurrency.RON, 'RO'], // Romania
  [TCurrency.RSD, 'RS'], // Serbia
  [TCurrency.RUB, 'RU'], // Russian Federation
  [TCurrency.RWF, 'RW'], // Rwanda
  [TCurrency.SAR, 'SA'], // Saudi Arabia
  [TCurrency.SBD, 'SB'], // Solomon Islands
  [TCurrency.SCR, 'SC'], // Seychelles
  [TCurrency.SEK, 'SE'], // Sweden
  [TCurrency.SGD, 'SG'], // Singapore
  [TCurrency.SHP, 'SH'], // Saint Helena
  [TCurrency.SLL, 'SL'], // Sierra Leone
  [TCurrency.SOS, 'SO'], // Somalia
  [TCurrency.SRD, 'SR'], // Suriname
  [TCurrency.STD, 'ST'], // Sao Tome and Principe
  [TCurrency.SVC, 'SV'], // El Salvador
  [TCurrency.SZL, 'SZ'], // Swaziland
  [TCurrency.THB, 'TH'], // Thailand
  [TCurrency.TJS, 'TJ'], // Tajikistan
  [TCurrency.TOP, 'TO'], // Tonga
  [TCurrency.TRY, 'TR'], // Turkey
  [TCurrency.TTD, 'TT'], // Trinidad and Tobago
  [TCurrency.TWD, 'TW'], // Taiwan, Province of China
  [TCurrency.TZS, 'TZ'], // Tanzania, United Republic of
  [TCurrency.UAH, 'UA'], // Ukraine
  [TCurrency.UGX, 'UG'], // Uganda
  [TCurrency.USD, 'US'], // United States
  [TCurrency.UYU, 'UY'], // Uruguay
  [TCurrency.UZS, 'UZ'], // Uzbekistan
  [TCurrency.VND, 'VN'], // Viet Nam
  [TCurrency.VUV, 'VU'], // Vanuatu
  [TCurrency.WST, 'WS'], // Samoa
  [TCurrency.XAF, 'CM'], // Cameroon
  [TCurrency.XCD, 'AI'], // Anguilla
  [TCurrency.XOF, 'BJ'], // Benin
  [TCurrency.XPF, 'PF'], // French Polynesia
  [TCurrency.YER, 'YE'], // Yemen
  [TCurrency.ZAR, 'ZA'], // South Africa
  [TCurrency.ZMW, 'ZM'], // Zambia
]);

export const countryToCodeMap = [
  ['Afghanistan', 'AF'],
  ['Albania', 'AL'],
  ['Algeria', 'DZ'],
  ['American Samoa', 'AS'],
  ['Andorra', 'AD'],
  ['Angola', 'AO'],
  ['Anguilla', 'AI'],
  ['Antarctica', 'AQ'],
  ['Antigua and Barbuda', 'AG'],
  ['Argentina', 'AR'],
  ['Armenia', 'AM'],
  ['Aruba', 'AW'],
  ['Australia', 'AU'],
  ['Austria', 'AT'],
  ['Azerbaijan', 'AZ'],
  ['Bahamas (the)', 'BS'],
  ['Bahrain', 'BH'],
  ['Bangladesh', 'BD'],
  ['Barbados', 'BB'],
  ['Belarus', 'BY'],
  ['Belgium', 'BE'],
  ['Belize', 'BZ'],
  ['Benin', 'BJ'],
  ['Bermuda', 'BM'],
  ['Bhutan', 'BT'],
  ['Bolivia (Plurinational State of)', 'BO'],
  ['Bonaire, Sint Eustatius and Saba', 'BQ'],
  ['Bosnia and Herzegovina', 'BA'],
  ['Botswana', 'BW'],
  ['Bouvet Island', 'BV'],
  ['Brazil', 'BR'],
  ['British Indian Ocean Territory (the)', 'IO'],
  ['Brunei Darussalam', 'BN'],
  ['Bulgaria', 'BG'],
  ['Burkina Faso', 'BF'],
  ['Burundi', 'BI'],
  ['Cabo Verde', 'CV'],
  ['Cambodia', 'KH'],
  ['Cameroon', 'CM'],
  ['Canada', 'CA'],
  ['Cayman Islands (the)', 'KY'],
  ['Central African Republic (the)', 'CF'],
  ['Chad', 'TD'],
  ['Chile', 'CL'],
  ['China', 'CN'],
  ['Christmas Island', 'CX'],
  ['Cocos (Keeling) Islands (the)', 'CC'],
  ['Colombia', 'CO'],
  ['Comoros (the)', 'KM'],
  ['Congo (the Democratic Republic of the)', 'CD'],
  ['Congo (the)', 'CG'],
  ['Cook Islands (the)', 'CK'],
  ['Costa Rica', 'CR'],
  ['Croatia', 'HR'],
  ['Cuba', 'CU'],
  ['Curaçao', 'CW'],
  ['Cyprus', 'CY'],
  ['Czechia', 'CZ'],
  ["Côte d'Ivoire", 'CI'],
  ['Denmark', 'DK'],
  ['Djibouti', 'DJ'],
  ['Dominica', 'DM'],
  ['Dominican Republic (the)', 'DO'],
  ['Ecuador', 'EC'],
  ['Egypt', 'EG'],
  ['El Salvador', 'SV'],
  ['Equatorial Guinea', 'GQ'],
  ['Eritrea', 'ER'],
  ['Estonia', 'EE'],
  ['Eswatini', 'SZ'],
  ['Ethiopia', 'ET'],
  ["Falkland Islands (the) [Malv'i,'nas]", 'FK'],
  ['Faroe Islands (the)', 'FO'],
  ['Fiji', 'FJ'],
  ['Finland', 'FI'],
  ['France', 'FR'],
  ['French Guiana', 'GF'],
  ['French Polynesia', 'PF'],
  ['French Southern Territories (the)', 'TF'],
  ['Gabon', 'GA'],
  ['Gambia (the)', 'GM'],
  ['Georgia', 'GE'],
  ['Germany', 'DE'],
  ['Ghana', 'GH'],
  ['Gibraltar', 'GI'],
  ['Greece', 'GR'],
  ['Greenland', 'GL'],
  ['Grenada', 'GD'],
  ['Guadeloupe', 'GP'],
  ['Guam', 'GU'],
  ['Guatemala', 'GT'],
  ['Guernsey', 'GG'],
  ['Guinea', 'GN'],
  ['Guinea-Bissau', 'GW'],
  ['Guyana', 'GY'],
  ['Haiti', 'HT'],
  ['Heard Island and McDonald Islands', 'HM'],
  ['Holy See (the)', 'VA'],
  ['Honduras', 'HN'],
  ['Hong Kong', 'HK'],
  ['Hungary', 'HU'],
  ['Iceland', 'IS'],
  ['India', 'IN'],
  ['Indonesia', 'ID'],
  ['Iran (Islamic Republic of)', 'IR'],
  ['Iraq', 'IQ'],
  ['Ireland', 'IE'],
  ['Isle of Man', 'IM'],
  ['Israel', 'IL'],
  ['Italy', 'IT'],
  ['Jamaica', 'JM'],
  ['Japan', 'JP'],
  ['Jersey', 'JE'],
  ['Jordan', 'JO'],
  ['Kazakhstan', 'KZ'],
  ['Kenya', 'KE'],
  ['Kiribati', 'KI'],
  ["Korea (the Democratic People's Republic of)", 'KP'],
  ['Korea (the Republic of)', 'KR'],
  ['Kuwait', 'KW'],
  ['Kyrgyzstan', 'KG'],
  ["Lao People's Democratic Republic (the)", 'LA'],
  ['Latvia', 'LV'],
  ['Lebanon', 'LB'],
  ['Lesotho', 'LS'],
  ['Liberia', 'LR'],
  ['Libya', 'LY'],
  ['Liechtenstein', 'LI'],
  ['Lithuania', 'LT'],
  ['Luxembourg', 'LU'],
  ['Macao', 'MO'],
  ['Madagascar', 'MG'],
  ['Malawi', 'MW'],
  ['Malaysia', 'MY'],
  ['Maldives', 'MV'],
  ['Mali', 'ML'],
  ['Malta', 'MT'],
  ['Marshall Islands (the)', 'MH'],
  ['Martinique', 'MQ'],
  ['Mauritania', 'MR'],
  ['Mauritius', 'MU'],
  ['Mayotte', 'YT'],
  ['Mexico', 'MX'],
  ['Micronesia (Federated States of)', 'FM'],
  ['Moldova (the Republic of)', 'MD'],
  ['Monaco', 'MC'],
  ['Mongolia', 'MN'],
  ['Montenegro', 'ME'],
  ['Montserrat', 'MS'],
  ['Morocco', 'MA'],
  ['Mozambique', 'MZ'],
  ['Myanmar', 'MM'],
  ['Namibia', 'NA'],
  ['Nauru', 'NR'],
  ['Nepal', 'NP'],
  ['Netherlands (the)', 'NL'],
  ['New Caledonia', 'NC'],
  ['New Zealand', 'NZ'],
  ['Nicaragua', 'NI'],
  ['Niger (the)', 'NE'],
  ['Nigeria', 'NG'],
  ['Niue', 'NU'],
  ['Norfolk Island', 'NF'],
  ['Northern Mariana Islands (the)', 'MP'],
  ['Norway', 'NO'],
  ['Oman', 'OM'],
  ['Pakistan', 'PK'],
  ['Palau', 'PW'],
  ['Palestine, State of', 'PS'],
  ['Panama', 'PA'],
  ['Papua New Guinea', 'PG'],
  ['Paraguay', 'PY'],
  ['Peru', 'PE'],
  ['Philippines (the)', 'PH'],
  ['Pitcairn', 'PN'],
  ['Poland', 'PL'],
  ['Portugal', 'PT'],
  ['Puerto Rico', 'PR'],
  ['Qatar', 'QA'],
  ['Republic of North Macedonia', 'MK'],
  ['Romania', 'RO'],
  ['Russian Federation (the)', 'RU'],
  ['Rwanda', 'RW'],
  ['Réunion', 'RE'],
  ['Saint Barthélemy', 'BL'],
  ['Saint Helena, Ascension and Tristan da Cunha', 'SH'],
  ['Saint Kitts and Nevis', 'KN'],
  ['Saint Lucia', 'LC'],
  ['Saint Martin (French part)', 'MF'],
  ['Saint Pierre and Miquelon', 'PM'],
  ['Saint Vincent and the Grenadines', 'VC'],
  ['Samoa', 'WS'],
  ['San Marino', 'SM'],
  ['Sao Tome and Principe', 'ST'],
  ['Saudi Arabia', 'SA'],
  ['Senegal', 'SN'],
  ['Serbia', 'RS'],
  ['Seychelles', 'SC'],
  ['Sierra Leone', 'SL'],
  ['Singapore', 'SG'],
  ['Sint Maarten (Dutch part)', 'SX'],
  ['Slovakia', 'SK'],
  ['Slovenia', 'SI'],
  ['Solomon Islands', 'SB'],
  ['Somalia', 'SO'],
  ['South Africa', 'ZA'],
  ['South Georgia and the South Sandwich Islands', 'GS'],
  ['South Sudan', 'SS'],
  ['Spain', 'ES'],
  ['Sri Lanka', 'LK'],
  ['Sudan (the)', 'SD'],
  ['Suriname', 'SR'],
  ['Svalbard and Jan Mayen', 'SJ'],
  ['Sweden', 'SE'],
  ['Switzerland', 'CH'],
  ['Syrian Arab Republic', 'SY'],
  ['Taiwan (Province of China)', 'TW'],
  ['Tajikistan', 'TJ'],
  ['Tanzania, United Republic of', 'TZ'],
  ['Thailand', 'TH'],
  ['Timor-Leste', 'TL'],
  ['Togo', 'TG'],
  ['Tokelau', 'TK'],
  ['Tonga', 'TO'],
  ['Trinidad and Tobago', 'TT'],
  ['Tunisia', 'TN'],
  ['Turkey', 'TR'],
  ['Turkmenistan', 'TM'],
  ['Turks and Caicos Islands (the)', 'TC'],
  ['Tuvalu', 'TV'],
  ['Uganda', 'UG'],
  ['Ukraine', 'UA'],
  ['United Arab Emirates (the)', 'AE'],
  ['United Kingdom of Great Britain and Northern Ireland (the)', 'GB'],
  ['United States Minor Outlying Islands (the)', 'UM'],
  ['United States of America (the)', 'US'],
  ['Uruguay', 'UY'],
  ['Uzbekistan', 'UZ'],
  ['Vanuatu', 'VU'],
  ['Venezuela (Bolivarian Republic of)', 'VE'],
  ['Viet Nam', 'VN'],
  ['Virgin Islands (British)', 'VG'],
  ['Virgin Islands (U.S.)', 'VI'],
  ['Wallis and Futuna', 'WF'],
  ['Western Sahara', 'EH'],
  ['Yemen', 'YE'],
  ['Zambia', 'ZM'],
  ['Zimbabwe', 'ZW'],
  ['Åland Islands', 'AX'],
];

export enum TIntervalUnit {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export const IntervalUnitToDisplay = {
  [TIntervalUnit.DAY]: 'Daily',
  [TIntervalUnit.MONTH]: 'Monthly',
  [TIntervalUnit.YEAR]: 'Yearly',
};

export const IntervalUnitToUnitDisplay = {
  [TIntervalUnit.DAY]: 'Day',
  [TIntervalUnit.MONTH]: 'Month',
  [TIntervalUnit.YEAR]: 'Year',
};

export const IntervalUnitToMomentUnit: { [key in TIntervalUnit]: unitOfTime.DurationConstructor } = {
  [TIntervalUnit.DAY]: 'day',
  [TIntervalUnit.WEEK]: 'week',
  [TIntervalUnit.MONTH]: 'month',
  [TIntervalUnit.YEAR]: 'year',
};

// if core model is processed through accounting journal entry pipeline
export enum TJournalEntryStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  PROCESSED = 'PROCESSED',
}

export enum TVendor {
  ADYEN = 'ADYEN',
  HSBC = 'HSBC',
  BRAINTREE_PAYPAL = 'BRAINTREE_PAYPAL',
  BRAINTREE_AMEX = 'BRAINTREE_AMEX',
  ADYEN_PAYPAL = 'ADYEN_PAYPAL',
  ADYEN_AMEX = 'ADYEN_AMEX',
  ADYEN_GOOGLE = 'ADYEN_GOOGLE',
  AMAZON_BUY_WITH_PRIME = 'AMAZON_BUY_WITH_PRIME',
  AMAZON_SELLER_CENTRAL = 'AMAZON_SELLER_CENTRAL',
  AMEX = 'AMEX',
  APPLE = 'APPLE',
  AUTHORIZE_NET = 'AUTHORIZE_NET',
  AVALARA = 'AVALARA',
  CLOVER = 'CLOVER',
  EBANX = 'EBANX',
  GOOGLE = 'GOOGLE',
  GOOGLE_BIG_QUERY = 'GOOGLE_BIG_QUERY',
  GOOGLE_PLAY_API = 'GOOGLE_PLAY_API',
  GOOGLE_PLAY_SALES_REPORT = 'GOOGLE_PLAY_SALES_REPORT',
  GOOGLE_PLAY_EARNINGS_REPORT = 'GOOGLE_PLAY_EARNINGS_REPORT',
  GOOGLE_PLAY_EARNINGS_REPORT_VERIFICATION = 'GOOGLE_PLAY_EARNINGS_REPORT_VERIFICATION',
  BRAINTREE = 'BRAINTREE',
  CHARGEBEE = 'CHARGEBEE',
  FLYWIRE = 'FLYWIRE',
  FORTUMO = 'FORTUMO',
  HOMEGROWN = 'HOMEGROWN',
  MODERN_TREASURY = 'MODERN_TREASURY',
  NETSUITE = 'NETSUITE',
  NMI_CLOVER = 'NMI_CLOVER',
  NMI_PAYMENTS = 'NMI_PAYMENTS',
  OANDA = 'OANDA',
  PAYMENTECH = 'PAYMENTECH',
  PAYPAL = 'PAYPAL',
  PLAID = 'PLAID',
  QUICKBOOKS = 'QUICKBOOKS',
  RECURLY = 'RECURLY',
  SALESFORCE = 'SALESFORCE',
  SHOPIFY = 'SHOPIFY',
  SHOP_CASH = 'SHOP_CASH',
  SHOP_PAY = 'SHOP_PAY',
  STRIPE = 'STRIPE',
  TELISPIRE = 'TELISPIRE',
  OPEN_EXCHANGE_RATES = 'OPEN_EXCHANGE_RATES',
  TIKTOK = 'TIKTOK',
  UNKNOWN_VENDOR = 'UNKNOWN_VENDOR',
}

export const getInstitutionDisplayValue = (institution: TVendor | TBankAccount) => {
  return {
    [TVendor.SHOP_PAY]: 'Shop Pay',
    [TVendor.ADYEN]: 'Adyen',
    [TVendor.AMAZON_BUY_WITH_PRIME]: 'Amazon Buy With Prime',
    [TVendor.AMAZON_SELLER_CENTRAL]: 'Amazon Seller Central',
    [TVendor.AMEX]: 'American Express',
    [TVendor.BRAINTREE_PAYPAL]: 'Braintree PayPal',
    [TVendor.BRAINTREE_AMEX]: 'Braintree AMEX',
    [TVendor.ADYEN_PAYPAL]: 'Adyen PayPal',
    [TVendor.ADYEN_AMEX]: 'Adyen AMEX',
    [TVendor.ADYEN_GOOGLE]: 'Adyen Google',
    [TVendor.APPLE]: 'Apple',
    [TVendor.AUTHORIZE_NET]: 'Authorize.net',
    [TVendor.AVALARA]: 'Avalara',
    [TVendor.CLOVER]: 'Clover',
    [TVendor.EBANX]: 'Ebanx',
    [TBankAccount.FIRST_REPUBLIC]: 'First Republic',
    [TVendor.GOOGLE]: 'Google',
    [TVendor.GOOGLE_BIG_QUERY]: 'Google Big Query',
    [TVendor.GOOGLE_PLAY_API]: 'Google',
    [TVendor.GOOGLE_PLAY_EARNINGS_REPORT]: 'Google',
    [TVendor.GOOGLE_PLAY_SALES_REPORT]: 'Google',
    [TVendor.GOOGLE_PLAY_EARNINGS_REPORT_VERIFICATION]: 'Google',
    [TVendor.BRAINTREE]: 'Braintree',
    [TVendor.CHARGEBEE]: 'Chargebee',
    [TVendor.HSBC]: 'HSBC',
    [TVendor.FLYWIRE]: 'Flywire',
    [TVendor.FORTUMO]: 'Fortumo',
    [TVendor.HOMEGROWN]: 'Homegrown',
    [TBankAccount.JPM]: 'JPMorgan Chase',
    [TBankAccount.MERCURY]: 'Mercury',
    [TVendor.MODERN_TREASURY]: 'Modern Treasury',
    [TVendor.NETSUITE]: 'Netsuite',
    [TVendor.NMI_PAYMENTS]: 'NMI Payments',
    [TVendor.NMI_CLOVER]: 'NMI and Clover',
    [TVendor.OANDA]: 'Oanda',
    [TVendor.PAYMENTECH]: 'Paymentech',
    [TVendor.PAYPAL]: 'PayPal',
    [TVendor.PLAID]: 'Plaid',
    [TVendor.QUICKBOOKS]: 'Quickbooks Online',
    [TVendor.RECURLY]: 'Recurly',
    [TVendor.SALESFORCE]: 'Salesforce',
    [TVendor.SHOPIFY]: 'Shopify',
    [TVendor.STRIPE]: 'Stripe',
    [TBankAccount.SVB]: 'SVB',
    [TVendor.TELISPIRE]: 'Telispire',
    [TBankAccount.WEBSTER]: 'Webster Bank',
    [TVendor.OPEN_EXCHANGE_RATES]: 'OPEN_EXCHANGE_RATES',
    [TVendor.SHOP_CASH]: 'SHOP CASH',
    [TVendor.TIKTOK]: 'TikTok',
    [TBankAccount.SYNOVUS]: 'Synovus Bank',
    [TVendor.UNKNOWN_VENDOR]: 'Unknown Vendor',
  }[institution];
};

export enum TBankAccount {
  SVB = 'SVB',
  JPM = 'JPM',
  HSBC = 'HSBC',
  MERCURY = 'MERCURY',
  WEBSTER = 'WEBSTER',
  FIRST_REPUBLIC = 'FIRST_REPUBLIC',
  SYNOVUS = 'SYNOVUS',
}

export const TInstitution = { ...TVendor, ...TBankAccount };

export const convertCurrencyOrThrow = (currencyString: string, errorMsg?: string): TCurrency => {
  const currency = currencyString as TCurrency;
  if (!Object.values(TCurrency).includes(currency)) {
    if (errorMsg) {
      throw new Error(errorMsg);
    } else {
      throw new Error(`${currencyString} is not in TCurrency`);
    }
  }
  return currency;
};
export const CurrencyToSymbol: { [key in TCurrency]?: string } = {
  [TCurrency.EUR]: '€',
  [TCurrency.USD]: '$',
  [TCurrency.GBP]: '£',
  [TCurrency.JPY]: '¥',
  [TCurrency.CNY]: '¥',
  [TCurrency.HKD]: 'HKD$',
  [TCurrency.CAD]: 'CAD$',
  [TCurrency.AUD]: 'AUD$',
  [TCurrency.NZD]: 'NZD$',
  [TCurrency.SGD]: 'SGD$',
  [TCurrency.TWD]: 'TWD$',
  [TCurrency.ARS]: 'ARS$',
  [TCurrency.MXN]: 'MXN$',
  [TCurrency.CLP]: 'CLP$',
  [TCurrency.COP]: 'COP$',
  [TCurrency.AED]: 'DH',
  [TCurrency.MYR]: 'RM',
  [TCurrency.NGN]: '₦',
  [TCurrency.BDT]: '৳',
  [TCurrency.BRL]: 'R$',
  [TCurrency.CHF]: 'CHF',
  [TCurrency.DKK]: 'kr',
  [TCurrency.EGP]: 'EGP',
  [TCurrency.NOK]: 'kr',
  [TCurrency.SEK]: 'kr',
  [TCurrency.IDR]: 'Rp',
  [TCurrency.INR]: '₹',
  [TCurrency.ILS]: '₪',
  [TCurrency.LKR]: 'Rs',
  [TCurrency.PKR]: 'Rs',
  [TCurrency.KES]: 'KES',
  [TCurrency.SAR]: 'SAR',
  [TCurrency.PLN]: 'zł',
  [TCurrency.PHP]: '₱',
  [TCurrency.RUB]: '₽',
  [TCurrency.THB]: '฿',
  [TCurrency.TRY]: '₺',
  [TCurrency.UAH]: '₴',
  [TCurrency.VND]: '₫',
  [TCurrency.KRW]: '₩',
  [TCurrency.PEN]: 'S/',
  [TCurrency.ZAR]: 'R',
};

export const CurrencyToRawToDisplayPowerDifference: { [key in TCurrency]: number } = {
  [TCurrency.AED]: 2,
  [TCurrency.AFN]: 2,
  [TCurrency.ALL]: 2,
  [TCurrency.AMD]: 2,
  [TCurrency.ANG]: 2,
  [TCurrency.AOA]: 2,
  [TCurrency.ARS]: 2,
  [TCurrency.AUD]: 2,
  [TCurrency.AWG]: 2,
  [TCurrency.AZN]: 2,
  [TCurrency.BAM]: 2,
  [TCurrency.BBD]: 2,
  [TCurrency.BDT]: 2,
  [TCurrency.BGN]: 2,
  [TCurrency.BIF]: 0,
  [TCurrency.BMD]: 2,
  [TCurrency.BND]: 2,
  [TCurrency.BOB]: 2,
  [TCurrency.BRL]: 2,
  [TCurrency.BSD]: 2,
  [TCurrency.BWP]: 2,
  [TCurrency.BZD]: 2,
  [TCurrency.CAD]: 2,
  [TCurrency.CDF]: 2,
  [TCurrency.CHF]: 2,
  [TCurrency.CLP]: 0,
  [TCurrency.CNY]: 2,
  [TCurrency.COP]: 2,
  [TCurrency.CRC]: 2,
  [TCurrency.CVE]: 2,
  [TCurrency.CZK]: 2,
  [TCurrency.DJF]: 0,
  [TCurrency.DKK]: 2,
  [TCurrency.DOP]: 2,
  [TCurrency.DZD]: 2,
  [TCurrency.EGP]: 2,
  [TCurrency.ETB]: 2,
  [TCurrency.EUR]: 2,
  [TCurrency.FJD]: 2,
  [TCurrency.FKP]: 2,
  [TCurrency.GBP]: 2,
  [TCurrency.GEL]: 2,
  [TCurrency.GHS]: 2,
  [TCurrency.GIP]: 2,
  [TCurrency.GMD]: 2,
  [TCurrency.GNF]: 0,
  [TCurrency.GTQ]: 2,
  [TCurrency.GYD]: 2,
  [TCurrency.HKD]: 2,
  [TCurrency.HNL]: 2,
  [TCurrency.HRK]: 2,
  [TCurrency.HTG]: 2,
  [TCurrency.HUF]: 2,
  [TCurrency.IDR]: 2,
  [TCurrency.ILS]: 2,
  [TCurrency.INR]: 2,
  // The Iraqi Dinar (IQD) is subdivided into 1,000 fils. See https://en.wikipedia.org/wiki/Iraqi_dinar
  [TCurrency.IQD]: 4,
  [TCurrency.ISK]: 0,
  [TCurrency.JMD]: 2,
  [TCurrency.JOD]: 3,
  [TCurrency.JPY]: 0,
  [TCurrency.KES]: 2,
  [TCurrency.KGS]: 2,
  [TCurrency.KHR]: 2,
  [TCurrency.KMF]: 0,
  [TCurrency.KRW]: 0,
  [TCurrency.KYD]: 2,
  [TCurrency.KZT]: 2,
  [TCurrency.LAK]: 2,
  [TCurrency.LBP]: 2,
  [TCurrency.LKR]: 2,
  [TCurrency.LRD]: 2,
  [TCurrency.LSL]: 2,
  [TCurrency.MAD]: 2,
  [TCurrency.MDL]: 2,
  [TCurrency.MGA]: 0,
  [TCurrency.MKD]: 2,
  [TCurrency.MMK]: 2,
  [TCurrency.MNT]: 2,
  [TCurrency.MOP]: 2,
  [TCurrency.MRO]: 2,
  [TCurrency.MUR]: 2,
  [TCurrency.MVR]: 2,
  [TCurrency.MWK]: 2,
  [TCurrency.MXN]: 2,
  [TCurrency.MYR]: 2,
  [TCurrency.MZN]: 2,
  [TCurrency.NAD]: 2,
  [TCurrency.NGN]: 2,
  [TCurrency.NIO]: 2,
  [TCurrency.NOK]: 2,
  [TCurrency.NPR]: 2,
  [TCurrency.NZD]: 2,
  [TCurrency.PAB]: 2,
  [TCurrency.PEN]: 2,
  [TCurrency.PGK]: 2,
  [TCurrency.PHP]: 2,
  [TCurrency.PKR]: 2,
  [TCurrency.PLN]: 2,
  [TCurrency.PYG]: 0,
  [TCurrency.QAR]: 2,
  [TCurrency.RON]: 2,
  [TCurrency.RSD]: 2,
  [TCurrency.RUB]: 2,
  [TCurrency.RWF]: 0,
  [TCurrency.SAR]: 2,
  [TCurrency.SBD]: 2,
  [TCurrency.SCR]: 2,
  [TCurrency.SEK]: 2,
  [TCurrency.SGD]: 2,
  [TCurrency.SHP]: 2,
  [TCurrency.SLL]: 2,
  [TCurrency.SOS]: 2,
  [TCurrency.SRD]: 2,
  [TCurrency.STD]: 2,
  [TCurrency.SVC]: 2,
  [TCurrency.SZL]: 2,
  [TCurrency.THB]: 2,
  [TCurrency.TJS]: 2,
  [TCurrency.TOP]: 2,
  [TCurrency.TRY]: 2,
  [TCurrency.TTD]: 2,
  [TCurrency.TWD]: 2,
  [TCurrency.TZS]: 2,
  [TCurrency.UAH]: 2,
  [TCurrency.UGX]: 0,
  [TCurrency.USD]: 2,
  [TCurrency.UYU]: 2,
  [TCurrency.UZS]: 2,
  [TCurrency.VND]: 0,
  [TCurrency.VUV]: 0,
  [TCurrency.WST]: 2,
  [TCurrency.XAF]: 0,
  [TCurrency.XCD]: 2,
  [TCurrency.XOF]: 0,
  [TCurrency.XPF]: 0,
  [TCurrency.YER]: 2,
  [TCurrency.ZAR]: 2,
  [TCurrency.ZMW]: 2,
};

export enum TUserRole {
  ADMIN = 'ADMIN',
  // AUDITOR = 'AUDITOR',
  VIEWER = 'VIEWER',
  ACCOUNTANT = 'ACCOUNTANT',
}

export enum TIntegrationStateType {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
}

export enum TIntegrationTypes {
  CURRENCY_EXCHANGE_SYNC = 'CURRENCY_EXCHANGE_SYNC',
  APPLE_FINANCE_REPORT = 'APPLE_FINANCE_REPORT',
  GOOGLE_PLAY_API = 'GOOGLE_PLAY_API',
  GOOGLE_PLAY_SALES_REPORT = 'GOOGLE_PLAY_SALES_REPORT',
  GOOGLE_PLAY_EARNINGS_REPORT = 'GOOGLE_PLAY_EARNINGS_REPORT',
  GOOGLE_PLAY_EARNINGS_REPORT_VERIFICATION = 'GOOGLE_PLAY_EARNINGS_REPORT_VERIFICATION',
  GOOGLE_CURRENCY_RATES_SYNC = 'GOOGLE_CURRENCY_RATES_SYNC',
  GOOGLE_BIG_QUERY = 'GOOGLE_BIG_QUERY',
  ADYEN = 'ADYEN',
  AUTHORIZE_NET = 'AUTHORIZE_NET',
  BRAINTREE = 'BRAINTREE',
  CHARGEBEE = 'CHARGEBEE',
  CLOVER = 'CLOVER',
  TIKTOK = 'TIKTOK',
  EBANX = 'EBANX',
  HOMEGROWN = 'HOMEGROWN',
  MODERN_TREASURY = 'MODERN_TREASURY',
  NETSUITE = 'NETSUITE',
  PAYMENTECH = 'PAYMENTECH',
  PLAID = 'PLAID',
  HSBC = 'HSBC',
  PAYPAL = 'PAYPAL',
  RECURLY = 'RECURLY',
  SALESFORCE = 'SALESFORCE',
  SHOPIFY = 'SHOPIFY',
  SIMPLE_SHOPIFY = 'SIMPLE_SHOPIFY',
  STRIPE = 'STRIPE',
  QUICKBOOKS = 'QUICKBOOKS',
  AMAZON_SELLER_CENTRAL = 'AMAZON_SELLER_CENTRAL',
  RECHARGE = 'RECHARGE',
}

export enum TIntegrationCategories {
  DATA = 'DATA',
  GENERAL_LEDGER = 'GENERAL_LEDGER',
}

/* These are extra integrations used for display purposes on ConnectIntegrationModal.
Once any of these integrations is ready for full product usage, it should be moved
to TIntegrationTypes. */
export enum TExtraIntegrationTypes {
  BLUESNAP = 'BLUESNAP',
  CHECKOUT = 'CHECKOUT',
  GOCARDLESS = 'GOCARDLESS',
  NMI = 'NMI',
  FLYWIRE = 'FLYWIRE',
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE',
  CHARGIFY = 'CHARGIFY',
  STRIPE_BILLING = 'STRIPE_BILLING',
  ZUORA = 'ZUORA',
  INTERNAL_BILLING_SYSTEMS = 'INTERNAL_BILLING_SYSTEMS',
  HUBSPOT = 'HUBSPOT',
  ZOHO = 'ZOHO',
  SNOWFLAKE = 'SNOWFLAKE',
  AWS = 'AWS',
  GOOGLE_CLOUD_PLATFORM = 'GOOGLE_CLOUD_PLATFORM',
  MICROSOFT_AZURE = 'MICROSOFT_AZURE',
  QUICKBOOKS = 'QUICKBOOKS',
  SAGE_INTACCT = 'SAGE_INTACCT',
  NETSUITE = 'NETSUITE',
  WORKDAY = 'WORKDAY',
  SAP = 'SAP',
  ORACLE = 'ORACLE',
  AUTHORIZE_NET = 'AUTHORIZE_NET',
  PAYSAFE = 'PAYSAFE',
  VERIFONE = 'VERIFONE',
  WORLD_PAY = 'WORLD_PAY',
  PAYROC = 'PAYROC',
  SPOTON = 'SPOTON',
  SQUARE = 'SQUARE',
  PAY_SIMPLE = 'PAY_SIMPLE',
  PADDLE = 'PADDLE',
  BILL = 'BILL',
  BILLSBY = 'BILLSBY',
  KLARNA = 'KLARNA',
  AFFIRM = 'AFFIRM',
  AFTERPAY = 'AFTERPAY',
  SEZZLE = 'SEZZLE',
  SPLITIT = 'SPLITIT',
  PIPELINE = 'PIPELINE',
  XERO = 'XERO',
  GRAVITY = 'GRAVITY',
}

export enum TCsvExportStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  READY = 'READY',
  NOTIFIED = 'NOTIFIED',
  ERROR = 'ERROR',
}

export enum TCsvPartStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  READY = 'READY',
  ERROR = 'ERROR',
}

// NB> The TReportPage and TNavItem enums are meant to encapsulate
// all reports and reports reachable by navbar, respectively
export enum TNavItem {
  HOME = 'HOME',
  DATA_HUB = 'DATA_HUB',
  REPORTS = 'REPORTS',
  FINANCIALS = 'FINANCIALS',
  EXPENSES = 'EXPENSES',
  DEPOSITS = 'DEPOSITS',
  REVENUE = 'REVENUE',
  JOURNAL = 'JOURNAL',
  ANALYTICS = 'ANALYTICS',
  AUDIT_TRAIL = 'AUDIT_TRAIL',
  RULES = 'RULES',
  PRICE_LIST = 'PRICE_LIST',
  INTEGRATIONS = 'INTEGRATIONS',
  TEAM = 'TEAM',
  COMPANY_SETTINGS = 'COMPANY_SETTINGS',
  MONITORING = 'MONITORING',
  EXCEPTIONS = 'EXCEPTIONS',
  SETTINGS = 'SETTINGS',
  RECONCILIATION = 'RECONCILIATION',
  TRANSACTION = 'TRANSACTION',
}

export const TReportPage = {
  ...TNavItem,
  REVENUE: 'REVENUE',
  REVENUE_CASH_DRILLDOWN: 'REVENUE_CASH_DRILLDOWN',
  REVENUE_REVENUE_DRILLDOWN: 'REVENUE_REVENUE_DRILLDOWN',
  REVENUE_TRANSACTION_TABLE: 'REVENUE_TRANSACTION_TABLE',
  INVOICE_ITEM_DRILLDOWN: 'INVOICE_ITEM_DRILLDOWN',
};

export enum TRevenueReportFilters {
  YEAR = 'Year',
}

export enum TPaymentRecognitionScheduleSections {
  INVOICE_OVERVIEW = 'Invoice Overview',
  PAYMENTS = 'Payments',
  EVENTS = 'Events',
  ACCOUNTING = 'Accounting',
}

export const TNavItemCollapsible = new Set<TNavItem>([TNavItem.FINANCIALS, TNavItem.MONITORING, TNavItem.SETTINGS]);

export enum TTwoFactorDeliveryType {
  EMAIL = 'EMAIL',
  GOOGLE_AUTHENTICATOR = 'GOOGLE_AUTHENTICATOR',
}

export enum TLoginStep {
  BEFORE_LOGIN = 'BEFORE_LOGIN',
  BEFORE_TWO_FACTOR = 'BEFORE_TWO_FACTOR',
  COMPLETED = 'COMPLETED',
}

export enum TVendorCostType {
  NETWORK_COST = 'NETWORK_COST',
  VENDOR_FEE = 'VENDOR_FEE',
  SERVICES_FEE = 'SERVICES_FEE',
  DISPUTE_FEE = 'DISPUTE_FEE',
  TRANSACTION_FEE = 'TRANSACTION_FEE',
  VENDOR_FEE_REFUND = 'VENDOR_FEE_REFUND',
  VENDOR_COGS = 'VENDOR_COGS',
  VENDOR_COGS_REFUND = 'VENDOR_COGS_REFUND',
}

export enum TUserAccountStatus {
  ACTIVE = 'ACTIVE',
  LOCKED = 'LOCKED',
}

export enum TEcheckStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export enum TPaymentStatus {
  PAID = 'PAID',
  PENDING = 'PENDING',
  IN_TRANSIT = 'IN_TRANSIT',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
}
export enum TRowFilterType {
  ALL = 'ALL',
  ZERO = 'ZERO',
  NON_ZERO = 'NON_ZERO',
}
